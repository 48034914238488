import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { CgProfile } from 'react-icons/cg';
import { FaPeopleGroup } from 'react-icons/fa6';
import { MdHistory, MdLogout, MdOutlinePhone } from 'react-icons/md';
import { PiHouseSimpleBold } from 'react-icons/pi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, MenuItems } from '@epcbuilder/lib/components';
import { AuthContext } from '@epcbuilder/lib/context/authContext';
import { ContactModal } from './ContactModal';

enum MobileMenuItems {
  PROPERTIES = 'properties',
  PROFILE = 'profile',
  COMPANY = 'company',
  JOBHISTORY = 'job-history',
}

const MenuItem = ({
  id,
  icon,
  text,
  active,
  setActive,
  href,
  onClick,
}: {
  id: MobileMenuItems;
  icon: JSX.Element;
  text: string;
  active: MobileMenuItems;
  setActive: Dispatch<SetStateAction<MobileMenuItems>>;
  href?: string;
  onClick?: () => void;
}) => {
  const baseClassNames =
    'rounded-full border-2 h-8 w-8 flex justify-center items-center cursor-pointer border-transparent';

  if (href) {
    return (
      <Link
        to={href}
        id={id}
        className="flex w-20 flex-col items-center gap-2"
        onClick={() => {
          setActive(id);
          onClick && onClick();
        }}
      >
        <div className={`${baseClassNames} ${active === id ? 'dark:bg-primary bg-blue text-color' : 'text-color'}`}>
          {icon}
        </div>
        <p>{text}</p>
      </Link>
    );
  }

  if (onClick) {
    return (
      <button
        type="button"
        id={id}
        className="flex w-20 flex-col items-center gap-2"
        onClick={() => {
          setActive(id);
          onClick();
        }}
      >
        <div
          className={`${baseClassNames} ${active === id ? 'bg-blue dark:bg-primary text-color shadow' : 'text-color'}`}
        >
          {icon}
        </div>
        <p>{text}</p>
      </button>
    );
  }

  return (
    <div id={id} className="flex w-20 flex-col items-center gap-2">
      <div
        className={`${baseClassNames} ${active === id ? 'bg-blue dark:bg-primary text-light shadow' : 'text-color'}`}
      >
        {icon}
      </div>
      <p>{text}</p>
    </div>
  );
};

const MobileBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatchLogout } = useContext(AuthContext);
  const [active, setActive] = useState<MobileMenuItems>(location.pathname.split('/')[1] as MobileMenuItems);
  const [profileMenuActive, setProfileMenuActive] = useState<boolean>(false);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);

  useEffect(() => {
    if (profileMenuActive) {
      document.body.style.overflowY = 'hidden';
      document.body.style.paddingRight = '5px';

      return () => {
        document.body.style.overflowY = 'scroll';
        document.body.style.paddingRight = '0';
      };
    }
  }, [profileMenuActive]);

  const userSettingsItems: MenuItems[] = [
    {
      id: 'sidebar-contact',
      text: 'Contact us',
      icon: <MdOutlinePhone size={20} />,
      onClick: () => setShowContactModal(true),
    },
    {
      id: 'sidebar-profile',
      text: 'Profile',
      icon: <CgProfile size={20} />,
      onClick: () => navigate('/profile'),
    },
    {
      id: 'sidebar-logout',
      text: 'Logout',
      icon: <MdLogout size={20} />,
      onClick: () => {
        dispatchLogout();
        navigate('/');
      },
    },
  ];

  return (
    <div className="bg-color dark:bg-dark-dark drop-shadow-brand-sm fixed bottom-0 z-50 w-full shadow sm:hidden">
      <div className="flex h-24 flex-row items-center justify-around gap-2 py-4">
        <MenuItem
          id={MobileMenuItems.PROPERTIES}
          href="/properties"
          icon={<PiHouseSimpleBold size={20} />}
          text="Properties"
          active={active}
          setActive={setActive}
          onClick={() => {
            setProfileMenuActive(false);
          }}
        />
        <MenuItem
          id={MobileMenuItems.JOBHISTORY}
          href="/job-history"
          icon={<MdHistory size={20} />}
          text="Job History"
          active={active}
          setActive={setActive}
          onClick={() => {
            setProfileMenuActive(false);
          }}
        />
        <MenuItem
          id={MobileMenuItems.COMPANY}
          href="/company"
          icon={<FaPeopleGroup size={20} />}
          text="Company"
          active={active}
          setActive={setActive}
          onClick={() => {
            setProfileMenuActive(false);
          }}
        />
        <MenuItem
          id={MobileMenuItems.PROFILE}
          icon={<CgProfile size={20} />}
          text="Profile"
          active={active}
          setActive={setActive}
          onClick={() => {
            setProfileMenuActive(!profileMenuActive);
            if (profileMenuActive) {
              setActive(location.pathname.split('/')[1] as MobileMenuItems);
            }
          }}
        />
      </div>
      {profileMenuActive && (
        <div className="absolute bottom-24 w-screen">
          <Menu items={userSettingsItems} className="border-neutral border-b-2" />
        </div>
      )}
      {showContactModal && <ContactModal setShowContactModal={setShowContactModal} />}
    </div>
  );
};

export default MobileBar;
